import { useQuery } from '@tanstack/react-query'
import { fetchDataService } from 'services'

const fetcher = async ({ queryKey }: Record<string, any>) => {
  const response = await fetchDataService.getTherapistClient(queryKey[1])
  return response
}

const useFetchClient = (clientId: number) => {
  return useQuery(['appointments', clientId], fetcher)
}

export default useFetchClient
