import {
  BodyNormal,
  BodyNormalSemiBold,
  BodySmall,
} from 'components/base/typography'
import { ButtonState } from '../Button/style'
import { Wrapper, Profile, Details, Image, Heading, Icon, Flex } from './style'
import Buttton from 'components/libs/Button'
import { Cancel } from 'assets/svg/index'
import { formatDate } from 'helpers'

interface SessionCardProps {
  name: string
  reason: string
  description: string
  time: string
  image: string
  show: boolean
  appointmentID: number
  type: string
  handleClose: () => void
  handleStartSession: (appointmentID: number) => void
}

const SessionDetails: React.FC<SessionCardProps> = ({
  name,
  reason,
  description,
  time,
  image,
  show,
  appointmentID,
  type,
  handleClose,
  handleStartSession,
}) => {
  const handleDateFormat = () => {
    const formattedDate = formatDate(time)

    return {
      date: formattedDate.date,
      time: formattedDate.time,
    }
  }

  return (
    <Wrapper show={show}>
      <Heading>
        <BodyNormalSemiBold color={'#344054'} data-testid={'name'}>
          Patient Details
        </BodyNormalSemiBold>

        <Icon onClick={() => handleClose()}>
          <Cancel />
        </Icon>
      </Heading>
      <Flex>
        <Profile>
          <Image>
            <img src={image} alt="A man" />
          </Image>
          <div>
            <BodyNormalSemiBold color={'#344054'} data-testid={'name'}>
              {name}
            </BodyNormalSemiBold>
          </div>
        </Profile>

        <div>
          <Details>
            <BodySmall color={'#667085'} data-testid={'reason'}>
              Type
            </BodySmall>
            <BodyNormal
              data-testid={'status'}
              style={{ textTransform: 'capitalize' }}
            >
              {type} Therapy
            </BodyNormal>
          </Details>
          <Details>
            <BodySmall color={'#667085'} data-testid={'reason'}>
              Reason
            </BodySmall>
            <BodyNormal data-testid={'status'}>
              {reason ?? 'No Reason Specified'}
            </BodyNormal>
          </Details>
          <Details>
            <BodySmall color={'#667085'} data-testid={'reason'}>
              Description
            </BodySmall>
            <BodyNormal data-testid={'status'}>
              {description ??
                "No Note Specified, If the client left a note in advance of the session, it'll show up here."}
            </BodyNormal>
          </Details>
          <Details>
            <BodySmall color={'#667085'} data-testid={'reason'}>
              Date
            </BodySmall>
            <BodyNormal data-testid={'status'}>
              {handleDateFormat().date}
            </BodyNormal>
          </Details>
          <Details>
            <BodySmall color={'#667085'} data-testid={'reason'}>
              TIME
            </BodySmall>
            <BodyNormal data-testid={'status'}>
              {handleDateFormat().time}
            </BodyNormal>
          </Details>
        </div>

        <Buttton
          variant={ButtonState.PRIMARY}
          padding={'16px'}
          border_radius={'8px'}
          type="button"
          onClick={() => handleStartSession(appointmentID)}
        >
          Start session
        </Buttton>
      </Flex>
    </Wrapper>
  )
}

export default SessionDetails
