import React from 'react'
import styled, { keyframes } from 'styled-components'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { canStartSession } from '../History'
import { BodySmall } from 'components/base/typography'

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
const DropdownContent = styled(DropdownMenu.Content)`
  /* display: none; */
  background-color: white;
  font-family: 'Plus Jakarta Sans';
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  border: 1px solid red;
  border: 1px solid #e4e7ec;

  &[data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
`

const DropdownItem = styled(DropdownMenu.Item)`
  width: 'fit-content';
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 30px;
  padding: 20px;
  position: relative;
  user-select: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: #1b1d3e;
    color: #fff;
  }
`

interface DropDownProps {
  buttonComponent: React.ReactElement
  onStartSession: any
  onViewDetails: any
  onViewClient?: any
  appointmentID: number
  appointment: Record<string, any>
}

const Dropdown: React.FC<DropDownProps> = ({
  buttonComponent,
  onStartSession,
  onViewDetails,
  onViewClient,
  appointmentID,
  appointment,
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{buttonComponent}</DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownContent data-side="bottom" sideOffset={5}>
          {canStartSession(appointment.started_at) && (
            <DropdownItem
              onClick={() =>
                onStartSession(appointmentID, appointment.started_at)
              }
            >
              Start Session
            </DropdownItem>
          )}

          <DropdownItem onClick={() => onViewDetails(appointment)}>
            <BodySmall>View Details</BodySmall>
          </DropdownItem>
          {/* <DropdownItem onClick={() => onViewClient(appointment)}>
            <BodySmall>View Client</BodySmall>
          </DropdownItem> */}
        </DropdownContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default Dropdown
