import { BodyNormal, BodySmall } from './../../base/typography'
import { device } from 'helpers/breakpoints'
import styled from 'styled-components'
import { ScrollBar } from 'styles/global'

interface WrapperProps {
  show: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  width: 380px;
  height: 100vh;
  overflow: auto;
  border: 1px solid #eaecf0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: ${(p) => (p.show ? 0 : '-800px')};
  padding-bottom: 20px;
  gap: 30px;
  ${ScrollBar}

  transition: all 0.2s;

  @media ${device.tablet} {
    width: 100%;
  }

  & p {
    margin: 0;
  }

  & button {
    margin-top: 20px;
    width: 342px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-direction: column;
`
export const Heading = styled.div`
  height: 80px;
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export const Details = styled.div`
  padding: 20px 16px;
  border-bottom: 1px solid #eaecf0;
  border-top: 1px solid #eaecf0;

  ${BodyNormal} {
    color: #344054;
  }

  ${BodySmall} {
    text-transform: uppercase;
  }
`

export const Status = styled.div`
  border-radius: 27px;
  width: 100px;
  padding: 4px 16px;
  height: 32px;
  background: #eaecf5;
  color: #3e4784;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Image = styled.div`
  height: 133px;
  width: 133px;
  border-radius: 50%;
  margin-top: 40px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
  }
`

export const ButtonWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`

export const Icon = styled.span`
  width: 25px;
  height: 25px;
  margin-left: auto;

  & svg {
    color: #344054;
  }
`

const Button = styled.button`
  width: 100%;
  height: 3.25rem;
  padding: 4px 16px;
  border-radius: 8px;
  text-align: center;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s;
`

export const EndSession = styled(Button)`
  border: 1px solid #ec0b43;
  color: #fff;
  background-color: #ec0b43;

  &:hover {
    background-color: #ff0141;
    color: #fff;
  }
`
export const Flex = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
