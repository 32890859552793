import styled from 'styled-components'
import { device } from 'helpers/breakpoints'
import * as Tabs from '@radix-ui/react-tabs'
import Overview from '../ClientTabContent/Overview'
import Appoinments from '../ClientTabContent/Appoinments'
import Notes from '../ClientTabContent/Notes'
import Tasks from '../ClientTabContent/Tasks'

const Wrapper = styled.div`
  overflow: auto;
`

const Content = () => {
  return (
    <Wrapper>
      <Tabs.Content value="overview">
        <Overview />
      </Tabs.Content>
      <Tabs.Content value="appointments">
        <Appoinments />
      </Tabs.Content>
      <Tabs.Content value="notes">
        <Notes />
      </Tabs.Content>
      <Tabs.Content value="tasks">
        <Tasks />
      </Tabs.Content>
    </Wrapper>
  )
}

export default Content
