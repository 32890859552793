import { tabType } from 'types'

interface TabProps {
  name: string
  tabType: tabType
}

export function parseFirebaseChannelName(name: string) {
  return name.replace('channel-', 'chats/')
}

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return false // Return an empty string if the input is falsy
  return word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter and return the rest of the word
}
export const convertMinutesToHoursOrDays = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const remainingHours = hours % 24
  const remainingMinutes = Math.round(minutes % 60)

  if (days > 0) {
    return `${days} days, ${remainingHours} hours`
  }

  // check if it's less than 1 hour
  else if (hours < 1) {
    return `${Math.round(minutes)} minutes`
  } else {
    return `${remainingHours} hours, ${remainingMinutes} minutes`
  }
}

export const tabs: TabProps[] = [
  {
    name: 'Overview',
    tabType: 'overview',
  },
  {
    name: 'Appointments',
    tabType: 'appointments',
  },
  {
    name: 'Notes',
    tabType: 'notes',
  },
  {
    name: 'Tasks',
    tabType: 'tasks',
  },
]
