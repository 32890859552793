import React from 'react'
import * as RToggleGroup from '@radix-ui/react-toggle-group'
import styled from 'styled-components'
import { BodySmall } from 'components/base/typography'
import { device } from 'helpers/breakpoints'

const ToggleGroupRoot = styled(RToggleGroup.Root)`
  display: flex;
  background-color: transparent;
  flex-wrap: wrap;

  border-radius: 10px;
  align-items: stretch;
  width: fit-content;
  height: 50px;
  height: fit-content;
  overflow: hidden;
  gap: 20px;

  @media ${device.tablet} {
    width: 200px;
  }
`

const ToggleGroupItem = styled(RToggleGroup.Item)`
  background-color: white;
  color: var(--mauve-11);

  display: flex;
  border: 1px solid #e4e7ec;
  font-size: 15px;
  line-height: 1;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
  }

  &[data-state='on'] {
    background-color: #1a244e;

    color: #ffff;
  }

  @media ${device.tablet} {
    width: 200px;
  }
`
type toggleItem = {
  name: string
  value: string
  action: () => void
  position: string
}

interface ToggleGroupProps {
  toggleItems: Array<toggleItem>
}

const ToggleGroup: React.FC<ToggleGroupProps> = ({ toggleItems }) => (
  <ToggleGroupRoot
    className="ToggleGroup"
    type="single"
    defaultValue="center"
    aria-label="Text alignment"
  >
    {toggleItems.map((item) => (
      <ToggleGroupItem
        value={item.position}
        aria-label={`${item.position} aligned`}
        onClick={() => item.action()}
      >
        <BodySmall fontSize={'13px'}>
          {item.name}({item.value})
        </BodySmall>
      </ToggleGroupItem>
    ))}
  </ToggleGroupRoot>
)

export default ToggleGroup
