import { HttpMethod } from './HttpService'
import QueryService from './QueryService'

export type AcceptProps = number

export interface RejectProps {
  id: number
  reason: string
}

export interface CompleteProps {
  id: number
  missed: boolean
}

export interface MessageProps {
  id: number
  message: string
}

export interface NoteProps {
  user_id: number
  appointment_id: number
  title: string
  content: string
}

export interface UpdateNoteProps {
  noteID: number
  title: string
  content: string
}

export default class AppointmentService {
  constructor(private queryService: QueryService) {}

  async getAppointments(page: number) {
    return await this.queryService.fireRequest(
      {
        url: `appointments?page=${page}`,
        method: HttpMethod.GET,
      },
      true
    )
  }
  async getClientsAppointments(clientId: string, status: string) {
    return await this.queryService.fireRequest(
      {
        url: `/clients/${clientId}/appointments/${status}`,
        method: HttpMethod.GET,
      },
      true
    )
  }

  async getAppointment(data: AcceptProps) {
    return await this.queryService.fireRequest(
      {
        url: `appointments/${data}`,
        method: HttpMethod.GET,
      },
      true
    )
  }

  async completeAppointment(data: CompleteProps) {
    return await this.queryService.fireRequest(
      {
        url: `appointments/${data.id}/complete`,
        method: HttpMethod.POST,
        body: {
          missed: data.missed,
        },
      },
      true
    )
  }

  async acceptAppointment(data: AcceptProps) {
    const response = await this.queryService.fireRequest(
      {
        url: `appointments/${data}/respond/accept`,
        method: HttpMethod.POST,
      },
      true
    )
    return response
  }

  async rejectAppointment(data: RejectProps) {
    const response = await this.queryService.fireRequest(
      {
        url: `appointments/${data.id}/respond/reject`,
        method: HttpMethod.POST,
        body: {
          reason: data.reason,
        },
      },
      true
    )

    return response
  }

  async joinAppointmentCall(data: AcceptProps) {
    const reponse = await this.queryService.fireRequest(
      {
        url: `appointments/${data}/join`,
        method: HttpMethod.POST,
      },
      true
    )

    return reponse
  }

  async sendMessage(data: MessageProps) {
    const response = await this.queryService.fireRequest(
      {
        url: `appointments/${data.id}/message`,
        method: HttpMethod.POST,
        body: {
          message: data.message,
        },
      },

      true
    )

    return response
  }

  async addNewNote(data: NoteProps) {
    const noteObject =
      data.appointment_id === 0
        ? {
            user_id: data.user_id,
            title: data.title,
            content: data.content,
            appointment_id: null,
          }
        : data
    return await this.queryService.fireRequest(
      {
        url: `notes`,
        method: HttpMethod.POST,
        body: noteObject,
      },
      true
    )
  }

  async updateNote(data: UpdateNoteProps) {
    return await this.queryService.fireRequest(
      {
        url: `notes/${data.noteID}`,
        method: HttpMethod.POST,
        body: {
          title: data.title,
          content: data.content,
        },
      },
      true
    )
  }

  async deleteNote(data: AcceptProps) {
    return await this.queryService.fireRequest(
      {
        url: `notes/${data}`,
        method: HttpMethod.DELETE,
      },
      true
    )
  }
}
