import React, { useState, useEffect } from 'react'
import Header from './Header'
import { SubHeadingBold, BodyNormal } from 'components/base/typography'
import * as Tabs from '@radix-ui/react-tabs'
import styled from 'styled-components'
import { Note as NoteIcon } from 'assets/svg'
import { useFetchNotes, useFetchAppointmentNotes } from 'hooks/useNotes'
import Loader from 'components/libs/Loader'
import { getUserDataAndNotes } from './helpers'
import { EmptyNotes } from '../SessionNote/Notes'
import Content from './Content/Content'

const Wrapper = styled(Tabs.Root)`
  display: grid;
  grid-template-columns: 320px 1fr;
  min-height: calc(100% - 80px);
`
const LoaderWrapper = styled.div`
  height: 50%;
`

const Notes = () => {
  const { data, isLoading } = useFetchNotes()

  const userData = getUserDataAndNotes(data)

  const defaultUser = userData[0]

  const [userID, setUserID] = useState(defaultUser?.id)

  const {
    data: userNotes,
    refetch,
    isLoading: Loading,
  } = useFetchAppointmentNotes(userID)

  const handleFetchNotes = (userID: number) => {
    refetch()

    setUserID(userID)
  }

  useEffect(() => {
    setUserID(defaultUser?.id)
  }, [defaultUser])

  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <Loader width={'70px'} height={'70px'} color={'#344054'} size={'2'} />
        </LoaderWrapper>
      )}

      {data?.length !== 0 && !isLoading && (
        <Wrapper defaultValue={defaultUser.name}>
          <Header data={userData} handleAction={handleFetchNotes} />

          <div>
            {userData?.map((user: any) => (
              <Tabs.Content
                value={user.name}
                key={user.id}
                style={{ height: '100%', backgroundColor: '#fff' }}
              >
                <Content
                  data={userNotes}
                  userID={defaultUser?.id}
                  isLoading={Loading}
                />
              </Tabs.Content>
            ))}
          </div>
        </Wrapper>
      )}

      <Wrapper>
        {data?.length === 0 && !isLoading && (
          <EmptyNotes>
            <NoteIcon />
            <SubHeadingBold color={'#333B49'} marginTop={'23px'}>
              Notes are empty
            </SubHeadingBold>
            <BodyNormal color="#7E848F">
              Created notes will appear here
            </BodyNormal>
          </EmptyNotes>
        )}
      </Wrapper>
    </>
  )
}

export default Notes
