import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Calendar from 'react-calendar'

import Header from './Header'
import moment from 'moment'
import SideModal from 'components/libs/Modal/SideModal'
import SessionDetails from 'components/libs/SessionDetails'
import useUpcomingAppointments from 'hooks/appointments/useUpcomingAppointments'
import useAppointments from 'hooks/appointments/useAppointments'
import useFetchDashboard from 'hooks/useFetchDashboard'
import { withUseQuery } from 'hoc/withUseQuery'
import { TherapistToast } from 'helpers'

import {
  DesktopSessionDetails,
  TableWrapper,
  StatusCard,
  Profile,
  ViewMore,
  Grid,
  ActiveDay,
  Analytics,
  SessionCards,
  CalendarContainer,
  CalendarWrapper,
  CalendarHeader,
  ToggleContainer,
} from './style'

import { Card, InfoBox, Flex, IconBox } from 'components/libs/Analytics/style'
import { RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSessionDetails } from 'store/modules/modals/actions'
import Table from 'components/libs/Table'
import {
  BodyNormal,
  BodySmall,
  TitleThree,
  BodySmallSemiBold,
  TitleTwo,
} from 'components/base/typography'
import { Container } from '@chakra-ui/react'
// import { ResponseTime } from 'components/libs/Analytics/style'
// import { convertMinutesToHoursOrDays } from 'components/libs/AccountWidget/helpers'
import { ChevronLeft, ChevronRight, Kebab, InfoIcon, Time } from 'assets/svg'
import Dropdown from 'components/libs/Dropdown'
import Tooltip from 'components/libs/Tooltip'
import SessionCard from 'components/libs/SessionCard'

import 'styles/calendar.css'
import ToggleGroup from 'components/libs/ToggleGroup'

export interface DetailsType {
  name: string
  reason: string
  description: string
  time: string
  image: string
  id: string
  appointmentId: number
  type: string
}

interface Props {
  [key: string]: any
}

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

const Bookings = () => {
  const navigate = useNavigate()
  const [dateValue, onChange] = useState<Value>(new Date())
  const [status, setStatus] = useState<'all' | 'upcoming' | 'cancelled'>(
    'upcoming'
  )

  const { show_session_details } = useSelector(
    (state: RootState) => state.modals
  )
  const dispatch = useDispatch()
  const [showDetails, setShowDetails] = useState(false)
  const [details, setDetails] = useState<DetailsType>({
    name: '',
    image: '',
    time: '',
    description: '',
    reason: '',
    id: '',
    appointmentId: 0,
    type: '',
  })

  const handleToggle = () => {
    dispatch(toggleSessionDetails())
  }

  const { data: upcoming } = useUpcomingAppointments()

  const [currentPage, setCurrentPage] = useState(1)
  const { data: appointmentsData, isLoading: loading } =
    useAppointments(currentPage)
  const { data: dashboard } = useFetchDashboard()

  const appointmentsLists = appointmentsData?.appointments

  let appointments = appointmentsLists

  const filterAppointmentsByStatus = (status = 'all') => {
    if (status === 'all') {
      appointments = appointmentsLists
    } else if (status === 'upcoming') {
      appointments = upcoming
    } else {
      appointments = appointmentsLists?.filter(
        (appointment: any) => appointment.status === status
      )
    }
  }

  if (status === 'upcoming') {
    filterAppointmentsByStatus('upcoming')
  }

  if (status === 'all') {
    filterAppointmentsByStatus()
  }

  if (status === 'cancelled') {
    filterAppointmentsByStatus('cancelled')
  }
  // Get today's date
  const today = moment().startOf('day')

  // Filter appointments happening today
  const happeningToday = upcoming?.filter(
    (appointment: Record<string, any>) => {
      const appointmentDate = moment(appointment.started_at).startOf('day')
      return appointmentDate.isSame(today, 'day')
    }
  )

  // const stats = {
  //   name: 'Upcoming Sessions',
  //   value: dashboard?.stats.upcoming_appointments ?? 0,
  //   icon: <Time />,
  //   description: 'This is the total number of sessions coming up for you.',
  //   information: `${
  //     happeningToday?.length === 0 ? 'None' : happeningToday?.length ?? 'None'
  //   } happening today`,
  // }

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleOpenModal = (sessionDetails: any) => {
    setShowDetails(true)
    dispatch(toggleSessionDetails())

    const sessionObject = {
      name: `${sessionDetails.userDisplayName}`,
      image: sessionDetails.user.avatar,
      time: sessionDetails.started_at,
      description: sessionDetails.note,
      reason: sessionDetails.reason,
      id: sessionDetails.user.id,
      appointmentId: sessionDetails.id,
      type: sessionDetails.type,
    }

    console.log(sessionDetails)
    setDetails(sessionObject)
  }

  const handleCloseModal = () => {
    setShowDetails(false)
    dispatch(toggleSessionDetails())
  }

  const canStartSession = (
    appointmentID: number,
    time: string = details.time
  ) => {
    const sessionTime = moment(time).format()
    const now = moment(new Date())
    const difference = now.diff(sessionTime, 'minutes')

    const diff = difference < 70 && difference >= -20

    if (diff) {
      handleStartSession(appointmentID)
    } else {
      TherapistToast.error({
        msg: `Sorry, you can only join the session at least 10 mins before it starts.`,
      })
    }
  }

  const handleStartSession = async (appointmentID: number) => {
    navigate(`/dashboard/bookings/${appointmentID}`)
  }

  const dayOfAppointment = ({
    activeStartDate,
    date,
    view,
  }: {
    activeStartDate: any
    date: any
    view: any
  }) => {
    if (view === 'month') {
      const matchingAppointment = appointments?.find((appointment: any) => {
        const appointmentDate = new Date(appointment.started_at)
        return (
          appointmentDate.getFullYear() === date.getFullYear() &&
          appointmentDate.getMonth() === date.getMonth() &&
          appointmentDate.getDate() === date.getDate()
        )
      })

      return matchingAppointment ? <ActiveDay /> : null
    }
    return null
  }

  const analyticsData = [
    {
      name: 'Upcoming Sessions',
      value: dashboard?.stats.upcoming_appointments ?? 0,
      icon: <Time />,
      description: 'This is the total number of sessions coming up for you.',
      information: `${
        happeningToday?.length === 0 ? 'None' : happeningToday?.length ?? 'None'
      } happening today`,
    },

    {
      name: 'Pending Sessions',
      value: dashboard?.stats.pending_appointments ?? 0,
      icon: <Time />,
      description:
        'This is the total number of appointment/sessions you have not responded to.',
      information: 'Pending sessions will appear here',
    },
    {
      name: 'Missed Sessions',
      value: dashboard?.stats.missed_appointments ?? 0,
      icon: <Time />,
      description:
        'Missed Appointments are appointments where the client did not show up.',
      information: 'Missed sessions will appear here',
    },
    {
      name: 'Rejected Sessions',
      value: dashboard?.stats.rejected_appointments ?? 0,
      icon: <Time />,
      description:
        'This is the total number of sessions and appointments you rejected.',
      information: 'Rejected sessions will appear here',
    },
  ]

  const toggleAnalytics = [
    {
      name: 'All Appointments',
      value: dashboard?.stats.total_appointment ?? 0,
      action: () => setStatus('all'),
      position: 'left',
    },
    {
      name: 'Upcoming Appointments',
      value: dashboard?.stats.upcoming_appointments ?? 0,
      action: () => setStatus('upcoming'),
      position: 'center',
    },
    {
      name: 'Cancelled Appointments',
      value:
        appointmentsLists?.filter(
          (appointment: any) => appointment.status === 'cancelled'
        ).length ?? 0,
      action: () => setStatus('cancelled'),
      position: 'right',
    },
  ]

  const filteredAppointmentsByDate = () => {
    // Parse the provided date using Moment.js
    const searchDate = moment(+dateValue!)

    // Filter appointments that match the provided date
    const matchingAppointments = appointments?.filter((appointment: any) => {
      const appointmentDate = moment(appointment.started_at)
      return appointmentDate.isSame(searchDate, 'day')
    })

    return matchingAppointments
  }

  const appointmentsByDate = filteredAppointmentsByDate()

  return (
    <Container>
      <Header showDetails={showDetails} />
      <DesktopSessionDetails>
        <SessionDetails
          show={showDetails}
          name={details.name}
          image={details.image}
          time={details.time}
          reason={details.reason}
          appointmentID={details.appointmentId}
          description={details.description}
          type={details.type}
          handleClose={handleCloseModal}
          handleStartSession={canStartSession}
        />
      </DesktopSessionDetails>

      <SideModal
        show={show_session_details}
        handleAction={handleToggle}
        width={'380px'}
        direction="right"
        display="mobile"
      >
        <SessionDetails
          show={show_session_details}
          name={details.name}
          image={details.image}
          time={details.time}
          reason={details.reason}
          appointmentID={details.appointmentId}
          type={details.type}
          description={details.description}
          handleClose={handleCloseModal}
          handleStartSession={canStartSession}
        />
      </SideModal>
      <Analytics>
        {analyticsData.map((stats: any) => (
          <Card key={stats.name}>
            <InfoBox>
              <BodySmallSemiBold color={'#5D6673'}>
                {stats.name}
              </BodySmallSemiBold>
              <Tooltip content={stats.description}>
                <InfoIcon />
              </Tooltip>
            </InfoBox>

            <Flex>
              <TitleThree>{stats.value}</TitleThree>
              <IconBox>{stats.icon}</IconBox>
            </Flex>
            {/* {stats} */}
            <BodySmall color={'#9EA3AB'} fontSize={'12px'}>
              {stats.information}
            </BodySmall>
          </Card>
        ))}
      </Analytics>

      <ToggleContainer>
        <ToggleGroup toggleItems={toggleAnalytics} />
      </ToggleContainer>

      <Grid>
        <CalendarContainer>
          <CalendarWrapper>
            <CalendarHeader>
              <TitleThree marginBottom={'10px'}>My Calendar</TitleThree>
              <BodySmall color={'#9EA3AB'}>
                Select a date to see the corresponding booking schedule.
              </BodySmall>
            </CalendarHeader>

            <Calendar
              onChange={onChange}
              value={dateValue}
              nextLabel={<ChevronRight />}
              prevLabel={<ChevronLeft />}
              tileContent={dayOfAppointment}
            />
          </CalendarWrapper>

          <SessionCards>
            {appointmentsByDate?.length === 0 && (
              <BodySmall>You don't have an appointment today! 😊</BodySmall>
            )}
            {appointmentsByDate?.map((appointment: any) => (
              <SessionCard
                handleClick={() => handleOpenModal(appointment)}
                key={appointment.id}
                name={`${appointment.user.first_name} ${appointment.user.last_name} `}
                image={appointment.user.avatar}
                startedAt={appointment.started_at}
                endedAt={appointment.ended_at}
                reason={appointment.reason}
                status={appointment.status}
              />
            ))}
          </SessionCards>
        </CalendarContainer>

        <TableWrapper>
          <Table
            label="All Bookings"
            columns={[
              {
                title: 'Client',
                dataIndex: 'client',
                key: 'client',
              },
              {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
              },
              // {
              //   title: 'Reason',
              //   dataIndex: 'reason',
              //   key: 'reason',
              // },
              {
                title: 'Date & Time',
                dataIndex: 'dateTime',
                key: 'dateTime',
              },
              // {
              //   title: 'Response Time',
              //   dataIndex: 'responseTime',
              //   key: 'responseTime',
              // },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
              },
              {
                title: '',
                dataIndex: 'view',
                key: 'view',
              },
            ]}
            loading={loading}
            dataSource={
              appointments && appointments.length > 0
                ? appointments.map((data: Props) => {
                    return {
                      key: `${data.id}`,
                      client: (
                        <Profile
                          key={data.id}
                          as="button"
                          onClick={() => {
                            handleCloseModal()
                            navigate(`/dashboard/client/${data.user.id}`)
                          }}
                        >
                          <img
                            src={data?.user?.avatar}
                            alt={data.userDisplayName}
                          />
                          <BodyNormal color={'#1B1D3E'} fontSize={'14px'}>
                            {data?.userDisplayName}
                          </BodyNormal>
                        </Profile>
                      ),
                      type: (
                        <BodyNormal fontSize={'14px'} color={'#1B1D3E'}>
                          {data?.type}
                        </BodyNormal>
                      ),
                      // reason: (
                      //   <BodyNormal color={'#1B1D3E'} fontSize={'14px'}>
                      //     {data?.reason}
                      //   </BodyNormal>
                      // ),
                      dateTime: (
                        <>
                          <BodyNormal color={'#1B1D3E'} fontSize={'14px'}>
                            {moment(data?.started_at).format('Do MMM YYYY')}
                          </BodyNormal>
                          <BodyNormal
                            color={'#9EA3AB'}
                            style={{ textTransform: 'uppercase' }}
                            fontSize={'14px'}
                          >
                            {moment(data.started_at).format('h:mm a')} -
                            {moment(data.ended_at).format('h:mm a')}
                          </BodyNormal>
                        </>
                      ),
                      // responseTime: (
                      //   <ResponseTime
                      //     rating={Math.floor(
                      //       getDateTimeDifference(
                      //         data.created,
                      //         data.responded_at
                      //       ) / 60
                      //     )}
                      //   >
                      //     <BodyNormal>
                      //       {data.status === 'pending'
                      //         ? 'Awaiting response'
                      //         : convertMinutesToHoursOrDays(
                      //             getDateTimeDifference(
                      //               data.created,
                      //               data.responded_at
                      //             ) ?? 0
                      //           )}
                      //     </BodyNormal>
                      //   </ResponseTime>
                      // ),
                      status: (
                        <StatusCard status={data.status}>
                          <BodyNormal fontSize={'14px'}>
                            {data.status.charAt(0).toUpperCase() +
                              data.status.slice(1)}
                          </BodyNormal>
                        </StatusCard>
                      ),
                      view: (
                        <Dropdown
                          onStartSession={canStartSession}
                          appointment={data}
                          // onViewClient = {navigate(`/dashboard/client/${data.user.id}`) }
                          onViewDetails={handleOpenModal}
                          appointmentID={data.id}
                          buttonComponent={
                            <ViewMore>
                              <Kebab width={20} color="#1B1D3E" />
                            </ViewMore>
                          }
                        />
                      ),
                    }
                  })
                : []
            }
            paginationData={appointmentsData?.meta?.links}
            currentPage={currentPage}
            handlePagination={handlePagination}
            handleAction={handleOpenModal}
          />
        </TableWrapper>
      </Grid>
    </Container>
  )
}

export default withUseQuery(Bookings)
