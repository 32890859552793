import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import moment from 'moment'
import SideModal from 'components/libs/Modal/SideModal'
import SessionDetails from 'components/libs/SessionDetails'
import { withUseQuery } from 'hoc/withUseQuery'
import { TherapistToast } from 'helpers'

import { StatusCard, Profile, ViewMore } from 'pages/bookings/style'

import { RateContainer, TableWrapper } from './styles'

import { RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSessionDetails } from 'store/modules/modals/actions'
import Table from 'components/libs/Table'
import { BodyNormal } from 'components/base/typography'
import { Container } from '@chakra-ui/react'
import { Kebab, Star, StarRate } from 'assets/svg'
import Dropdown from 'components/libs/Dropdown'

import 'styles/calendar.css'
import useFetchClientAppointments from 'hooks/clients/useFetchClientAppointments'

export interface DetailsType {
  name: string
  reason: string
  description: string
  time: string
  image: string
  id: string
  appointmentId: number
  type: string
}

interface Props {
  [key: string]: any
}

const Appointments = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { show_session_details } = useSelector(
    (state: RootState) => state.modals
  )
  const dispatch = useDispatch()
  const [showDetails, setShowDetails] = useState(false)
  const [details, setDetails] = useState<DetailsType>({
    name: '',
    image: '',
    time: '',
    description: '',
    reason: '',
    id: '',
    appointmentId: 0,
    type: '',
  })

  const handleToggle = () => {
    dispatch(toggleSessionDetails())
  }

  const { data: allAppointments, isLoading: loading } =
    useFetchClientAppointments(Number(id), '')

  const appointmentsLists = allAppointments?.appointments

  let appointments = appointmentsLists

  const handleOpenModal = (sessionDetails: any) => {
    setShowDetails(true)
    dispatch(toggleSessionDetails())

    const sessionObject = {
      name: `${sessionDetails.userDisplayName}`,
      image: sessionDetails.user.avatar,
      time: sessionDetails.started_at,
      description: sessionDetails.note,
      reason: sessionDetails.reason,
      id: sessionDetails.user.id,
      appointmentId: sessionDetails.id,
      type: sessionDetails.type,
    }

    setDetails(sessionObject)
  }

  const handleCloseModal = () => {
    setShowDetails(false)
    dispatch(toggleSessionDetails())
  }

  const canStartSession = (
    appointmentID: number,
    time: string = details.time
  ) => {
    const sessionTime = moment(time).format()
    const now = moment(new Date())
    const difference = now.diff(sessionTime, 'minutes')

    const diff = difference < 70 && difference >= -20

    if (diff) {
      handleStartSession(appointmentID)
    } else {
      TherapistToast.error({
        msg: `Sorry, you can only join the session at least 10 mins before it starts.`,
      })
    }
  }

  const handleStartSession = async (appointmentID: number) => {
    navigate(`/dashboard/bookings/${appointmentID}`)
  }

  return (
    <Container>
      <SideModal
        show={show_session_details}
        handleAction={handleToggle}
        width={'380px'}
        direction="right"
        display="desktop"
      >
        <SessionDetails
          show={show_session_details}
          name={details.name}
          image={details.image}
          time={details.time}
          reason={details.reason}
          appointmentID={details.appointmentId}
          type={details.type}
          description={details.description}
          handleClose={handleCloseModal}
          handleStartSession={canStartSession}
        />
      </SideModal>

      <TableWrapper>
        <Table
          label=""
          columns={[
            {
              title: 'Reason',
              dataIndex: 'reason',
              key: 'reason',
            },
            {
              title: 'Note',
              dataIndex: 'note',
              key: 'note',
            },
            {
              title: 'Date & Time',
              dataIndex: 'dateTime',
              key: 'dateTime',
            },
            {
              title: 'Ratings',
              dataIndex: 'ratings',
              key: 'ratings',
            },
          ]}
          loading={loading}
          dataSource={
            appointments && appointments.length > 0
              ? appointments.map((data: Props) => {
                  return {
                    key: `${data.id}`,
                    reason: (
                      <BodyNormal color={'#1B1D3E'} fontSize={'14px'}>
                        {data?.reason ?? 'No reason provided'}
                      </BodyNormal>
                    ),
                    note: (
                      <BodyNormal fontSize={'14px'} color={'#1B1D3E'}>
                        {data?.note ?? 'No notes provided'}
                      </BodyNormal>
                    ),
                    dateTime: (
                      <>
                        <BodyNormal color={'#1B1D3E'} fontSize={'14px'}>
                          {moment(data?.started_at).format('Do MMM YYYY')}
                        </BodyNormal>
                        <BodyNormal
                          color={'#9EA3AB'}
                          style={{ textTransform: 'uppercase' }}
                          fontSize={'14px'}
                        >
                          {moment(data.started_at).format('h:mm a')} -
                          {moment(data.ended_at).format('h:mm a')}
                        </BodyNormal>
                      </>
                    ),
                    ratings: (
                      <>
                        {data?.rating ? (
                          <Ratings count={data?.rating} />
                        ) : (
                          'No rating'
                        )}
                      </>
                    ),
                  }
                })
              : []
          }
          // paginationData={appointmentsData?.meta?.links}
          // currentPage={currentPage}
          // handlePagination={handlePagination}
          // handleAction={handleOpenModal}
        />
      </TableWrapper>
    </Container>
  )
}

const Ratings: React.FC<{ count: number }> = ({ count }) => {
  return (
    <RateContainer>
      {Array.from({ length: count }).map((_, index) => (
        <StarRate key={index} />
      ))}
    </RateContainer>
  )
}

export default withUseQuery(Appointments)
